import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import TextareaAutosize from "react-textarea-autosize";

import { setCommunities } from "../../../features/communities";
import { setContext } from "../../../features/context";

import axiosCall from "../../../lib/axios";

const CommunityEdit = ({ language, setEditCommunity, signout }) => {
  ///////////////// INITIALIZE /////////////////
  const community = useSelector((state) => state.context.value);
  const communities = useSelector((state) => state.communities.value);
  const dispatch = useDispatch();

  const [communityType, setCommunityType] = useState(community.type);
  const [exclusive, setExclusive] = useState(community.exclusive || false);
  const [verified, setVerified] = useState(community.verified || false);
  const [enterprizeCap, setEnterprizeCap] = useState(
    community.enterprize_cap || 0
  );
  const [remittance, setRemittance] = useState(community.remittance || false);

  ///////////////// FUNCTIONS /////////////////
  async function resetCommunities(obj) {
    let _communities = [...communities];
    for (var i = 0; i < _communities.length; i++) {
      if (_communities[i].id === community.id) {
        _communities[i] = obj;
        return _communities;
      }
    }
  }

  const applyChanges = async () => {
    let data = { cid: community.id };

    let dirty = false;

    if (community.type !== communityType) {
      dirty = true;
      data.type = communityType;
    }

    let cap = Math.floor(enterprizeCap);
    if (cap < 0) cap = 0;
    setEnterprizeCap(cap);
    if (community.enterprize_cap !== cap) {
      dirty = true;
      data.enterprize_cap = cap;
    }

    if ((community.verified || false) !== verified) {
      dirty = true;
      data.verified = verified;
    }

    if ((community.exclusive || false) !== exclusive) {
      dirty = true;
      data.exclusive = exclusive;
    }

    if ((community.remittance || false) !== remittance) {
      dirty = true;
      data.remittance = remittance;
    }

    if (dirty) {
      let result = await axiosCall("admin/community/config", data);
      console.log(result);
      if (result.success) {
        let _community = { ...community };
        if (data.type) {
          _community.type = data.type;
        }
        if (data.enterprize_cap !== undefined) {
          _community.enterprize_cap = data.enterprize_cap;
        }
        if (data.verified !== undefined) {
          _community.verified = data.verified;
        }
        if (data.exclusive !== undefined) {
          _community.exclusive = data.exclusive;
        }
        if (data.remittance !== undefined) {
          _community.remittance = data.remittance;
        }
        // set context
        dispatch(setContext(_community));
        localStorage.setItem("context", JSON.stringify(_community));
        // set communities
        const _communities = await resetCommunities(_community);
        dispatch(setCommunities(_communities));
        localStorage.setItem("communities", JSON.stringify(_communities));
        setEditCommunity(false);
      } else if (result.refresh) {
        // token has been refreshed, try again
        applyChanges();
      } else {
        // refresh token expired or unknown error
        signout();
      }
    }
  };

  ///////////////// RENDER GUI /////////////////
  return (
    <div className="page-section mobile" style={{ marginTop: "0.5em" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div className="mobile-justify" style={{ display: "flex" }}>
          <div className="heading-icon">s</div>
          <div className="subheading" style={{ overflow: "initial" }}>
            {language.labels.app.config}
          </div>
        </div>
      </div>

      {/* community type */}
      <div
        style={{
          marginTop: "1.5em",
          display: "flex",
          alignItems: "center",
          gap: "0.5em",
        }}
      >
        <div>{language.labels.communities.community_type}</div>
        <select
          onChange={(e) => {
            setCommunityType(e.target.value);
          }}
          value={communityType}
          autoFocus
        >
          <option value="corporate" selected={community.type === "corporate"}>
            corporate
          </option>

          <option value="customer" selected={community.type === "customer"}>
            customer
          </option>

          <option
            value="institution"
            selected={community.type === "institution"}
          >
            institution
          </option>

          <option value="public" selected={community.type === "public"}>
            public
          </option>
        </select>
      </div>

      {/* enterprize cap */}
      <div
        style={{
          marginTop: "1.5em",
          display: "flex",
          alignItems: "center",
          gap: "0.5em",
        }}
      >
        <div>Enterprise Cap</div>
        <input
          id="verified_selector"
          value={enterprizeCap}
          type="number"
          min="0"
          step="1"
          onChange={(e) => {
            setEnterprizeCap(e.target.value);
          }}
          style={{ width: "6em" }}
        />
      </div>
      <div className="quote-text" style={{ marginTop: "0.2em" }}>
        Number of complimentary (enterprise) accounts granted to community
        personnel. Default 0.
      </div>

      {/* verified */}
      <div
        style={{
          marginTop: "1.5em",
          display: "flex",
          alignItems: "center",
          gap: "0.5em",
        }}
      >
        <input
          id="verified_selector"
          value={verified}
          type="checkbox"
          checked={verified}
          onChange={() => {
            setVerified(!verified);
          }}
        />
        <label htmlFor="verified_selector">Verified</label>
      </div>

      {/* exclusive */}
      <div
        style={{
          marginTop: "1.5em",
          display: "flex",
          alignItems: "center",
          gap: "0.5em",
        }}
      >
        <input
          id="exclusive_selector"
          value={exclusive}
          type="checkbox"
          checked={exclusive}
          onChange={() => {
            setExclusive(!exclusive);
          }}
        />
        <label htmlFor="exclusive_selector">Exclusive</label>
      </div>

      {/* remittance */}
      <div
        style={{
          marginTop: "1.5em",
          display: "flex",
          alignItems: "center",
          gap: "0.5em",
        }}
      >
        <input
          id="remittance_selector"
          value={remittance}
          type="checkbox"
          checked={remittance}
          onChange={() => {
            setRemittance(!remittance);
          }}
        />
        <label htmlFor="remittance_selector">Remittance</label>
      </div>
      <div className="quote-text" style={{ marginTop: "0.2em" }}>
        Remittance only applicable to public communities
      </div>

      {/* buttons */}
      <div style={{ display: "flex", gap: "0.25em", margin: "3em 0" }}>
        <button
          className="button-secondary"
          onClick={() => setEditCommunity(false)}
        >
          {language.labels.app.cancel}
        </button>
        <button className="button" onClick={applyChanges}>
          {language.labels.app.apply}
        </button>
      </div>
    </div>
  );
};

export default CommunityEdit;
