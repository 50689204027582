import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import TextareaAutosize from "react-textarea-autosize";
import axios from "axios";

import refreshToken from "../../../lib/refreshToken";
import axiosCall from "../../../lib/axios";
import Card from "../../../lib/previewLinks";
import { naturalDate } from "../../../lib/date";
import { formatBytes, url2Key } from "../../../lib/common";

import Modal from "../../modal/Modal";

import ImageView from "../../modal/Image";
import config from "../../../config";

const LessonViewer = ({
  style,
  language,
  community,
  currentLesson,
  setCurrentLesson,
  onClose,
  onChange,
  onDelete,
  bite,
  signout,
}) => {
  //////////// INITIALIZATION ///////////
  const userData = useSelector((state) => state.basic.value);

  const titleInput = useRef();
  const outcomesInput = useRef();
  const planInput = useRef();
  const leaveEditor = useRef();
  const image_key = useRef();

  const [HTML, setHTML] = useState("");
  const [editor, setEditor] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [confirmClosure, setConfirmClosure] = useState(false);
  const [poster, setPoster] = useState({});
  const [alias, setAlias] = useState(null);
  const [images, setImages] = useState([]);
  const [imageModal, setImageModal] = useState(false);
  const [imageErr, setImageErr] = useState(null);
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [uploadModal, setUploadModal] = useState(false);
  const [processing, setProcessing] = useState(true);

  useEffect(() => {
    if (currentLesson.user) {
      getPoster();
    } else {
      setPoster({ name: community.uname, image: community.crest });
    }
    //fix images
    let _images = [];
    for (const img of currentLesson.images) {
      _images.push({
        src: config.content.cache + img.key,
        name: img.originalname,
        key: img.key,
      });
    }
    setImages(_images);
  }, []);

  useEffect(() => {
    if (editor) {
      if (titleInput.current) titleInput.current.value = currentLesson.title;
      if (outcomesInput.current)
        outcomesInput.current.value = currentLesson.outcomes;
      if (planInput.current) planInput.current.value = currentLesson.plan;
    }
  }, [editor]);

  useEffect(() => {
    fixHTML();
  }, [currentLesson]);

  //////////// LESSON ///////////
  const processLinksMeta = async (meta, post, obj, card, processed) => {
    let _post = post;
    for (const _obj of meta) {
      if (_obj.link === obj.link) {
        processed.push(obj.link);
        post = post.replaceAll(obj.link, card);
        _post = _post.replaceAll(obj.link, card);
      } else if (processed.indexOf(_obj.link) < 0) {
        post = post.replaceAll(_obj.link, _obj.card);
      }
    }
    return { post, _post, processed };
  };

  const updateLinksMeta = async (meta, post) => {
    let processed = [];
    for (const obj of meta) {
      const result = await axiosCall(
        "knowledge/preview/link",
        { link: obj.link },
        false
      );
      const card = await Card({ ...result.data, link: obj.link });
      const p = await processLinksMeta(meta, post, obj, card, processed);
      processed = p.processed;
      post = p._post;
      setHTML(p.post);
    }
  };

  const fixHTML = async () => {
    if (currentLesson.links && currentLesson.links.length > 0) {
      let html = currentLesson.plan;
      let meta = [];
      for (const link of currentLesson.links) {
        const card = await Card({ link });
        meta.push({ link, card });
        html = html.replaceAll(link, card);
      }
      setHTML(html);
      updateLinksMeta(meta, currentLesson.plan);
    } else {
      setHTML(currentLesson.plan);
    }
  };

  //////////// POSTER ///////////
  const getPoster = async () => {
    const result = await axiosCall(
      "poster/info",
      {
        id: currentLesson.user,
        type: "user",
        community: currentLesson.community,
      },
      false
    );
    if (result.success) {
      setPoster(result.data.poster);
      setAlias(result.data.role.email_alias || null);
    } else if (result.refresh) {
      //token has been refreshed, try again
      getPoster();
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  ////////////// EDIT TEXT //////////////////
  const changeBasic = async (e, change = true) => {
    let val = e.target.getAttribute("name");
    let keyed = null;
    if (e.key) keyed = val;

    //set data
    let payload = { id: currentLesson._id };
    let current_value;
    switch (val) {
      case "title_field":
        payload.title = titleInput.current.value.trim();
        current_value = titleInput.current.value.trim();
        break;
      case "outcomes_field":
        payload.outcomes = outcomesInput.current.value.trim();
        current_value = outcomesInput.current.value.trim();
        break;
      case "plan_field":
        payload.plan = planInput.current.value.trim();
        current_value = planInput.current.value.trim();
        break;
    }

    //check for changes
    if (
      !change ||
      !current_value ||
      (val === "title_field" && current_value === currentLesson.title) ||
      (val === "outcomes_field" && current_value === currentLesson.outcomes) ||
      (val === "plan_field" && current_value === currentLesson.plan)
    ) {
      //just reset and close editor
      setBlur(val, true, keyed);
      return;
    }

    // try and submit data
    let result = await axiosCall("lesson/edit", payload);
    if (result.success) {
      onChange();
      if (result.status === 200) {
        setCurrentLesson(result.data);
        setBlur(val, true, keyed);
      } else {
        onClose();
      }
    } else if (result.refresh) {
      //token has been refreshed, try again
      changeBasic(e, change);
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  //clear focus
  const clearFocus = (excluding = "") => {
    if (excluding !== "title_field")
      titleInput.current.value = currentLesson.title;
    if (excluding !== "outcomes_field")
      outcomesInput.current.value = currentLesson.outcomes;
    if (excluding !== "plan_field")
      planInput.current.value = currentLesson.plan;
  };
  const setBlur = (name, reblur = false, keyed = null) => {
    //timeout workaround to allow time for focus to shift if keyboard is used instead of mouse
    setTimeout(() => {
      if (reblur) {
        document.activeElement.blur();
      } else if (name !== document.activeElement.getAttribute("name")) {
        clearFocus();
      }
      if (keyed) {
        //set focus to next field
        if (keyed === "title_field") {
          outcomesInput.current.focus();
        } else if (keyed === "outcomes_field") {
          planInput.current.focus();
        } else {
          leaveEditor.current.focus();
        }
      }
    }, 100);
  };

  //////////// IMAGES ///////////
  const openImageViewer = (index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  };

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const addImage = async () => {
    setImageErr(null);
    try {
      const file = document.getElementById("image-file-input").files[0];
      //gather data
      const formdata = new FormData();
      formdata.append("image", file);
      formdata.append("id", currentLesson._id);

      //call API
      const configurationObject = {
        url: `${config.server.api}/lesson/image/add/hub/${localStorage.getItem(
          "client_id"
        )}`,
        method: "POST",
        data: formdata,
        headers: { "Content-Type": "multipart/form-data" },
        withCredentials: true,
        credentials: "include",
      };

      setUploadModal(true);
      setProcessing(true);

      document.getElementById("loader").style.display = "inline";
      axios(configurationObject)
        .then(async (response) => {
          console.log(response);
          if (response.status === 202) {
            let r = await refreshToken();
            if (r) {
              //token refreshed try again
              addImage();
            } else {
              //refresh token expired
              signout();
            }
          } else {
            //success
            onChange();
            setProcessing(false);
            let _images = [...images];
            _images.push(response.data);
            setImages(_images);
            setCurrentLesson({ ...currentLesson, images: _images });
            document.getElementById("loader").style.display = "none";
          }
        })
        .catch((error) => {
          //unknown error
          signout();
        });
    } catch (err) {
      setImageErr(language.labels.error.file);
    }
  };

  const readImageFile = async (event) => {
    if (event.target.files.length > 0) {
      if (event.target.files[0].size > config.max_attachment) {
        const size = formatBytes(config.max_attachment);
        setImageErr(
          language.labels.error.exceeded_one.replace(/{size}/g, size)
        );
      }

      await new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.onloadend = async function (event) {
          const arr = new Uint8Array(event.target.result).subarray(0, 3);
          let hexcode = "";
          for (let index = 0; index < 3; index++) {
            if (index > 0) hexcode += "-";
            hexcode += arr[index].toString(16);
          }

          if (config.file.imageCodes.includes(hexcode)) {
            addImage();
          } else {
            setImageErr(language.labels.error.image);
          }
        };
        fileReader.readAsArrayBuffer(event.target.files[0]);
        resolve();
      });
    }
  };

  const removeImageFile = (index) => {
    image_key.current = images[index].key;
    setImageModal(true);
  };

  const deleteImage = async () => {
    setImageModal(false);
    let result = await axiosCall("lesson/image/remove", {
      id: currentLesson._id,
      key: image_key.current,
    });
    if (result.success) {
      if (result.status === 200) {
        onChange();
        let _images = new Array();
        for (var i = 0; i < images.length; i++) {
          if (images[i].key !== result.data) _images.push(images[i]);
        }
        setImages(_images);
        setCurrentLesson({ ...currentLesson, images: _images });
      }
    } else if (result.refresh) {
      //token has been refreshed, try again
      deleteImage();
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  //////////// ACTIONS ///////////
  const deleteLesson = async () => {
    let result = await axiosCall("lesson/delete", {
      lid: currentLesson._id,
    });
    if (result.success) {
      setDeleteModal(false);
      onDelete();
    } else if (result.refresh) {
      //token has been refreshed, try again
      deleteLesson();
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  //////////// RENDER GUI ///////////

  const imageAttachments = images.map((file, index) => (
    <div
      key={index}
      className="file-container"
      onClick={() => removeImageFile(index)}
      role="img"
      aria-label={file.name}
    >
      <div
        role="button"
        className="close glyphs"
        custom={index}
        tabIndex={0}
        onKeyDown={(e) =>
          (e.key === "Enter" || e.key === " ") && removeImageFile(index)
        }
        aria-label={language.labels.image.remove}
      >
        x
      </div>
      <img
        className="message-uploaded-image"
        src={file.src}
        aria-hidden="true"
      />
    </div>
  ));

  return (
    <div className="thread" style={style}>
      {/* back */}
      {!editor && bite && (
        <button
          className="link"
          style={{ marginBottom: "1em" }}
          onClick={() => onClose()}
        >
          {language.labels.bites.lessons_all}
        </button>
      )}

      {/* edit mode */}
      {editor && (
        <h2
          className="heading2 alert"
          style={{ fontSize: "1.1em", fontWeight: "600", margin: "0 0.25em" }}
        >
          {language.labels.app.edit_mode}
        </h2>
      )}

      {/* title */}
      {editor ? (
        <>
          <h3
            className="heading2"
            style={{ fontWeight: "500", margin: "1em 0.25em 0 0.25em" }}
          >
            {language.labels.lesson.title}
          </h3>
          <div
            className="natural-edit"
            style={{ display: "flex", maxWidth: "44rem" }}
          >
            <input
              id="title_field"
              ref={titleInput}
              autoComplete="off"
              style={{ flex: 1 }}
              name="title_field"
              onFocus={() => clearFocus("title_field")}
              onBlur={() => setBlur("title_field")}
              onKeyDown={(e) => e.key === "Enter" && changeBasic(e)}
              maxLength={config.string.title}
            />
            <div
              style={{
                flexBasis: "3.5rem",
                display: "flex",
                justifyContent: "space-around",
                margin: "-0.25rem",
              }}
            >
              <div
                role="button"
                className="glyphs accept"
                name="title_field"
                title={language.labels.app.apply}
                aria-label={language.labels.app.apply}
                onBlur={() => setBlur("title_field")}
                onClick={(e) => changeBasic(e, "title_field")}
                onKeyDown={(e) =>
                  (e.key === "Enter" || e.key === " ") && changeBasic(e)
                }
                tabIndex={0}
              >
                *
              </div>
              <div
                role="button"
                className="glyphs reject"
                name="title_field"
                style={{ fontSize: "0.7rem" }}
                title={language.labels.app.discard}
                aria-label={language.labels.app.discard}
                onBlur={() => setBlur("title_field")}
                onClick={(e) => {
                  changeBasic(e, false);
                }}
                onKeyDown={(e) =>
                  (e.key === "Enter" || e.key === " ") && changeBasic(e, false)
                }
                tabIndex={0}
              >
                x
              </div>
            </div>
          </div>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "0.5em",
            marginTop: "0em",
          }}
        >
          <div
            className="glyphs font-contrast-hard"
            style={{ fontSize: "1.6em" }}
          >
            l
          </div>
          <h2
            className="heading"
            style={{ padding: "0.5em 0", fontSize: "1.2em" }}
          >
            {currentLesson.title}
          </h2>
        </div>
      )}

      {/* Outcomes */}
      <h3
        className="heading2"
        style={{
          fontWeight: "500",
          margin: `${editor ? "1.5em 0.25em 0 0.25em" : "0.5em 0 0"}`,
        }}
      >
        {language.labels.lesson.outcomes}
      </h3>
      {editor ? (
        <div
          className="natural-edit"
          style={{
            maxWidth: "44rem",
          }}
        >
          <TextareaAutosize
            id="outcomes_field"
            ref={outcomesInput}
            minRows={4}
            autoComplete="off"
            style={{ width: "100%" }}
            name="outcomes_field"
            onFocus={() => clearFocus("outcomes_field")}
            onBlur={() => setBlur("outcomes_field")}
            maxLength={config.string.article}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              flex: 0,
              margin: "0 -0.5rem",
            }}
          >
            <div
              role="button"
              className="glyphs accept"
              name="outcomes_field"
              title={language.labels.app.apply}
              aria-label={language.labels.app.apply}
              onBlur={() => setBlur("outcomes_field")}
              onClick={(e) => {
                changeBasic(e);
              }}
              onKeyDown={(e) =>
                (e.key === "Enter" || e.key === " ") && changeBasic(e)
              }
              tabIndex={0}
            >
              *
            </div>
            <div
              role="button"
              className="glyphs reject"
              name="outcomes_field"
              style={{ fontSize: "0.7rem", margin: "0 0.25rem" }}
              title={language.labels.app.discard}
              aria-label={language.labels.app.discard}
              onBlur={() => setBlur("outcomes_field")}
              onClick={(e) => {
                changeBasic(e, false);
              }}
              onKeyDown={(e) =>
                (e.key === "Enter" || e.key === " ") && changeBasic(e, false)
              }
              tabIndex={0}
            >
              x
            </div>
          </div>
        </div>
      ) : (
        <div style={{ whiteSpace: "pre", marginTop: "0.25em" }}>
          {currentLesson.outcomes}
        </div>
      )}

      {/* lesson plan */}
      <h3
        className="heading2"
        style={{
          fontWeight: "500",
          margin: `${editor ? "1.5em 0.25em 0 0.25em" : "0.5em 0 0"}`,
        }}
      >
        {language.labels.lesson.plan}
      </h3>
      {/* pdf */}
      {currentLesson.pdf && currentLesson.status === "available" && (
        <div
          className="document"
          style={{
            height: 0.9 * window.innerHeight,
          }}
        >
          <object
            data={
              config.content.cache +
              currentLesson.pdf +
              "#view=fitH&toolbar=0&navpanes=0&scrollbar=1"
            }
            width="100%"
            height="100%"
          >
            <iframe
              src={
                config.content.cache +
                currentLesson.pdf +
                "#view=fitH&toolbar=0&navpanes=0&scrollbar=1"
              }
              width="100%"
              height="100%"
            />
          </object>
        </div>
      )}
      {currentLesson.status === "pending" && (
        <div>{language.labels.lesson.pending}</div>
      )}
      {/* plain text */}
      {!currentLesson.pdf && HTML !== "" && editor ? (
        <>
          <div
            className="natural-edit"
            style={{
              maxWidth: "44rem",
            }}
          >
            <TextareaAutosize
              id="plan_field"
              ref={planInput}
              minRows={4}
              autoComplete="off"
              style={{ width: "100%" }}
              name="plan_field"
              onFocus={() => clearFocus("plan_field")}
              onBlur={() => setBlur("plan_field")}
              maxLength={config.string.article}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                flex: 0,
                margin: "0 -0.5rem",
              }}
            >
              <div
                role="button"
                className="glyphs accept"
                name="plan_field"
                title={language.labels.app.apply}
                aria-label={language.labels.app.apply}
                onBlur={() => setBlur("plan_field")}
                onClick={(e) => {
                  changeBasic(e);
                }}
                onKeyDown={(e) =>
                  (e.key === "Enter" || e.key === " ") && changeBasic(e)
                }
                tabIndex={0}
              >
                *
              </div>
              <div
                role="button"
                className="glyphs reject"
                name="plan_field"
                style={{ fontSize: "0.7rem", margin: "0 0.25rem" }}
                title={language.labels.app.discard}
                aria-label={language.labels.app.discard}
                onBlur={() => setBlur("plan_field")}
                onClick={(e) => {
                  changeBasic(e, false);
                }}
                onKeyDown={(e) =>
                  (e.key === "Enter" || e.key === " ") && changeBasic(e, false)
                }
                tabIndex={0}
              >
                x
              </div>
            </div>
          </div>

          <div style={{ marginTop: "1em" }}>
            <label
              role="button"
              style={{ padding: "0 0.25em" }}
              tabIndex="0"
              htmlFor="image-file-input"
              className="link"
              onKeyDown={(e) =>
                (e.key === "Enter" || e.key === " ") &&
                document.getElementById("image-file-input").click()
              }
            >
              {language.labels.app.image_button}
            </label>
            <input
              id="image-file-input"
              style={{ display: "none" }}
              onChange={(e) => readImageFile(e)}
              type="file"
              accept={config.file.imageTypes.toString()}
              multiple={false}
            />
          </div>

          {images.length > 0 && (
            <div
              className="message-file-view"
              aria-label={language.labels.aria.selected_images}
            >
              {imageAttachments}
            </div>
          )}
          {imageErr && (
            <div
              style={{ fontWeight: 600, marginTop: "0.5rem" }}
              className="errtext"
              role="alert"
            >
              {imageErr}
            </div>
          )}
        </>
      ) : (
        <div style={{ display: "flex" }}>
          <div
            style={{
              width: "100%",
              whiteSpace: "pre-wrap",
              maxWidth: "66rem",
            }}
            dangerouslySetInnerHTML={{ __html: HTML }}
          />
        </div>
      )}

      {/* images */}
      {!editor && !isViewerOpen && images.length > 0 && (
        <div style={{ margin: "1em -2px" }}>
          <div style={{ fontWeight: "500", margin: "0 2px" }}>
            {language.labels.app.image_count.replace(/{n}/g, images.length)}
          </div>
          {images.map((file, index) => (
            <div
              key={index}
              className="message-image-container"
              onClick={() => openImageViewer(index)}
              role="img"
              aria-label={file.name}
            >
              <img
                className="message-image"
                src={file.src}
                aria-hidden="true"
                alt={file.name}
              />
            </div>
          ))}
        </div>
      )}
      {!editor && (
        <ImageView
          images={images}
          currentImage={currentImage}
          setCurrentImage={setCurrentImage}
          show={isViewerOpen}
          onClose={closeImageViewer}
          language={language}
        />
      )}

      {editor ? (
        // leave editor
        <div style={{ display: "flex", gap: "0.25em", margin: "3em 0" }}>
          <button
            ref={leaveEditor}
            className="button-secondary"
            onClick={() => setEditor(false)}
          >
            {language.labels.app.edit_mode_leave}
          </button>
        </div>
      ) : (
        <>
          {/* poster */}
          <div
            className="heading2"
            style={{ marginTop: "1em", fontStyle: "italic" }}
          >
            {language.labels.lesson.by}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "4px",
              marginTop: "0.5em",
              maxWidth: "288px",
            }}
          >
            <div
              className="avatar med"
              style={{
                flexShrink: "0",
                backgroundImage: `url(${config.content.server + poster.image})`,
              }}
            ></div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div className="name" style={{ fontSize: "1em" }}>
                {poster.name}
              </div>
              <div className="contrast" style={{ fontSize: "11px" }}>
                {alias ? alias : language.labels.app.expert}
              </div>

              <div style={{ fontSize: "0.7em", marginTop: "2px" }}>
                {naturalDate(
                  currentLesson.created,
                  language.locale,
                  language.labels.date
                )}
              </div>
            </div>
          </div>

          {/* edit */}
          {!currentLesson.pdf &&
            ((community.role !== "role_member" &&
              community.role !== "role_instructor" &&
              !currentLesson.user) ||
              currentLesson.user === userData.uid) && (
              <button
                className="button-edit"
                style={{ padding: "0 1em", marginTop: "1.5em" }}
                title={language.labels.lesson.edit}
                aria-label={language.labels.lesson.edit}
                onClick={() => {
                  setEditor(true);
                }}
              >
                <div aria-hidden="true" className="glyphs font-contrast">
                  w
                </div>
                <div style={{ padding: "0 .2rem" }}></div>
                <div style={{ flexGrow: 0 }}>{language.labels.lesson.edit}</div>
              </button>
            )}

          {/* delete */}
          {(currentLesson.user === userData.uid ||
            (community.role !== "role_member" &&
              community.role !== "role_instructor")) && (
            <button
              className="link"
              style={{ marginTop: "1.5em", fontSize: "0.9em" }}
              onClick={() => setDeleteModal(true)}
            >
              {language.labels.lesson.delete}
            </button>
          )}
        </>
      )}

      {/* DELETE IMAGE MODAL */}
      <Modal
        className="modal-dynamic"
        title={language.labels.image.remove_sure}
        onOk={deleteImage}
        onClose={function () {
          setImageModal(false);
        }}
        show={imageModal}
        language={language}
      ></Modal>

      {/* DELETE LESSON MODAL */}
      <Modal
        className="modal-dynamic"
        title={language.labels.lesson.delete}
        show={deleteModal}
      >
        <div
          style={{ fontSize: "1.1em" }}
          dangerouslySetInnerHTML={{
            __html: language.labels.app.delete_warning,
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            fontSize: ".9rem",
            marginTop: "1rem",
          }}
        >
          <input
            id="consequence_understood"
            type={"checkbox"}
            onChange={(e) => setConfirmClosure(e.target.checked)}
            defaultChecked={confirmClosure}
          />
          <div style={{ minWidth: "0.5rem" }}></div>
          <label className="hover" htmlFor="consequence_understood">
            {language.labels.app.consequences_understood}
          </label>
        </div>
        {confirmClosure && (
          <div style={{ display: "flex", marginTop: "0.5em" }}>
            <button className="button" onClick={() => deleteLesson()}>
              {language.labels.lesson.delete_now}
            </button>
          </div>
        )}
        <div style={{ display: "flex", marginTop: "2em" }}>
          <button className="button-off" onClick={() => setDeleteModal(false)}>
            {language.labels.app.cancel}
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default LessonViewer;
