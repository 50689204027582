import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import axiosCall from "../../lib/axios";
import config from "../../config";
import { duration, preciseDate } from "../../lib/date";

const LiveStream = ({ language, main_focus, signout }) => {
  //////////// INITIALIZE ///////////
  const community = useSelector((state) => state.context.value);

  const userData = useSelector((state) => state.basic.value);

  const { state } = useLocation();
  const navigate = useNavigate();
  const [stream, setStream] = useState({});
  const [str, setStr] = useState(null); //stringify stream to update on attributes change
  const [bites, setBites] = useState([]);
  const [recent, setRecent] = useState(false);

  useEffect(() => {
    getStream();
  }, []);

  const [isDocumentVisible, setIsDocumentVisible] = useState(!document.hidden);

  const handleVisibilityChange = () => {
    setIsDocumentVisible(!document.hidden);
  };

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  const getStream = async () => {
    if (state.streamId) {
      //get stream
      let result = await axiosCall("live/streaminfo", {
        streamId: state.streamId,
      });
      if (result.success) {
        setStream(result.data.stream);
        setStr(JSON.stringify(result.data.stream));
        setBites(result.data.bites);
        pingCheck(result.data.stream);
      } else if (result.refresh) {
        //token has been refreshed, try again
        getStream();
      } else {
        //refresh token expired or unknown error
        signout();
      }
    }
  };

  ////////////// ACTIONS //////////////////
  const joinStream = async () => {
    console.log("join");
    // let result = await axiosCall("live/resume", { streamId: state.streamId });
  };

  const resumeStream = async () => {
    console.log("resume");
    // let result = await axiosCall("live/resume", { streamId: state.streamId });
  };

  const killStream = async () => {
    console.log("kill");
    // let result = await axiosCall("live/resume", { streamId: state.streamId });
  };

  const getViewers = async () => {
    console.log("viewers");
    // let result = await axiosCall("live/resume", { streamId: state.streamId });
  };

  ////////////// PING //////////////////
  const pingCheck = async (s) => {
    // determine if recently ended
    let _recent = false;
    if (s.ended) {
      _recent =
        new Date() - new Date(s.ended) < config.expiration_ms.recent_live;
      setRecent(_recent);
    }

    // ping after 10 seconds
    if (window.location.pathname === "/livestream") {
      if (_recent || !s.ended) {
        setTimeout(() => {
          pingStream(s);
        }, 1000);
      }
    }
  };

  const pingStream = async (s) => {
    //update started, duration and ended if changed
    const ping = await getPing();
    if (ping.started) s.started = ping.started;
    if (ping.duration) s.duration = ping.duration;
    if (ping.ended) {
      s.ended = ping.ended;
    } else {
      delete s.ended;
    }
    setStream(s);
    setStr(JSON.stringify(s));
    pingCheck(s);
  };

  const getPing = async (attempt = 1) => {
    try {
      let result = await axiosCall(
        "live/ping",
        { streamId: state.streamId },
        false
      );
      if (result.success) {
        return result.data;
      } else if (result.refresh) {
        throw new Error();
      } else {
        //refresh token expired or unknown error
        signout();
      }
    } catch (err) {
      if (attempt < 2) {
        return getPing(attempt + 1);
      } else {
        //unknown error
        signout();
      }
    }
  };

  ////////////// RENDER GUI //////////////////
  if (stream.publisher) {
    return (
      <main className="main-page" ref={main_focus} tabIndex={0}>
        {/* heading */}
        <div className="page-section">
          <h1 className="heading">{language.labels.live.learning_stream}</h1>
        </div>

        {/* publisher */}
        <div className="page-section">
          <div style={{ display: "flex", gap: "0.5em" }}>
            <div
              role="img"
              aria-label={stream.username}
              className="thumb-block auto-margin-narrow"
              style={{
                backgroundImage: `url("${
                  config.content.server + stream.avatar
                }")`,
                borderRadius: "50%",
              }}
            ></div>
            <div>
              <h2
                className="heading"
                style={{ fontSize: "1.2em", margin: "0.2em 0" }}
              >
                {stream.streamName}
              </h2>
              <div>
                {language.labels.live.host.replace(/{name}/, stream.username)}
              </div>
              {stream.fullname && (
                <div className="handle2" style={{ marginTop: "0.1em" }}>
                  {stream.fullname}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="page-section">
          {/********* DETAILS  *********/}
          <div
            className="card-long"
            style={{
              display: "block",
              paddingBottom: "2em",
            }}
          >
            {/* status */}
            <h2
              className={
                stream.ended
                  ? "heading2 middle"
                  : stream.started
                  ? "heading2 alert"
                  : "heading2 amber"
              }
              style={{ fontWeight: 500, fontSize: "1.2em" }}
            >
              {stream.ended
                ? language.labels.live.streaming.ended
                : stream.started
                ? language.labels.live.streaming.now
                : language.labels.live.streaming.pending}
            </h2>

            {/* started/created */}
            <div style={{ fontWeight: 300, marginTop: "1em" }}>
              {stream.started
                ? language.labels.live.started.replace(
                    /{date}/,
                    preciseDate(stream.started, language.locale)
                  )
                : language.labels.live.created.replace(
                    /{date}/,
                    preciseDate(stream.created, language.locale)
                  )}
            </div>

            {stream.ended && (
              <>
                <div style={{ fontWeight: 300, marginTop: "0.5em" }}>
                  {language.labels.live.ended_time.replace(
                    /{time}/,
                    preciseDate(stream.ended, language.locale)
                  )}
                </div>

                <div style={{ fontWeight: 300, marginTop: "0.5em" }}>
                  {language.labels.live.duration.replace(
                    /{time}/g,
                    duration(stream.duration / 1000)
                  )}
                </div>
              </>
            )}

            {/* action buttons */}
            <div
              style={{
                display: "flex",
                gap: "0.5em",
                marginTop: "1.5em",
                fontSize: "0.9em",
              }}
            >
              {recent && stream.ended && stream.publisher === userData.uid && (
                <button className="button narrow" onClick={resumeStream}>
                  {language.labels.live.resume}
                </button>
              )}
              {!stream.ended && stream.started && (
                <button className="button narrow" onClick={joinStream}>
                  {language.labels.live.join}
                </button>
              )}
              {!stream.ended && (
                <button className="button alert" onClick={killStream}>
                  {language.labels.live.end}
                </button>
              )}
              {stream.ended && stream.publisher === userData.uid && (
                <button
                  className="button-secondary narrow"
                  onClick={getViewers}
                >
                  {language.labels.live.viewers.button}
                </button>
              )}
            </div>
          </div>

          {/********* FOCUS  *********/}
          <div
            className="card-long"
            style={{
              display: "block",
              paddingBottom: "2em",
            }}
          >
            <h2
              className="heading2"
              style={{ fontWeight: 300, fontSize: "1.2em" }}
            >
              {language.labels.live.focus}
            </h2>
            {/* pathway */}
            <div
              style={{
                display: "flex",
                gap: "0.5em",
                alignItems: "center",
                margin: "1.5em 0 1em",
              }}
            >
              <div role="img" className="thumb-glyph tiny auto-margin-narrow">
                p
              </div>
              <div style={{ fontWeight: 500, flex: 1 }}>
                {stream.pathwayTitle}
              </div>
            </div>

            {/* bites */}
            {bites.map((bite, index) => (
              <div
                style={{
                  display: "flex",
                  gap: "0.5em",
                  alignItems: "center",
                  marginTop: "0.5em",
                }}
              >
                <div
                  role="img"
                  className="thumb-block border tiny auto-margin-narrow"
                  style={{
                    backgroundImage: `url("${
                      config.content.server + bite.thumb
                    }")`,
                  }}
                ></div>
                <div
                  className="quote-text"
                  style={{ fontSize: "0.9em", flex: 1 }}
                >
                  {bite.title}
                </div>
              </div>
            ))}
          </div>
        </div>
      </main>
    );
  } else {
    return <></>;
  }
};

export default LiveStream;
