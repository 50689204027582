import { React, useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Loader from "react-spinners/PuffLoader";
import Cookies from "universal-cookie";

import { setBasic, unsetBasic } from "./features/basic";
import { login, logout } from "./features/authentication";
import { setCommunities, unsetCommunities } from "./features/communities";
import { setContext, unsetContext } from "./features/context";
import { setEmails, unsetEmails } from "./features/emails";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import axios from "axios";

import "./css/Main.scss";
import "./css/Common.scss";
import "./css/Media.css";
import "./css/fonts.css";

import config from "./config";
import translator from "./lib/translator";
import logoff from "./lib/logoff";

import { Content } from "./UI/layout";
import { Login, Forgot, Password, Signup, TimedOut } from "./UI/auth";
import { Account } from "./UI/common";
import {
  Admin,
  AnswerFlag,
  Blocks,
  BlockDetails,
  CommunityHub,
  Context,
  Directory,
  Flagged,
  Hub,
  Invites,
  Knowledge,
  LivePublish,
  LiveStream,
  LiveStreams,
  Managers,
  NewBlock,
  NewBlocks,
  NewCommunity,
  NewKnowledge,
  NewPathway,
  NewVideo,
  Pathway,
  Pathways,
  PathwayStream,
  Thread,
  Topics,
  Video,
  VideoHub,
  Welcome,
} from "./UI/hub";
import {
  AccountDelete,
  Deeplink,
  Invitation,
  Redirect,
  Sso,
  Suspended,
  Terms,
  Vid,
} from "./UI/utility";
import { Find, Policy, PubCommunity, Support, Yongo } from "./UI/pub";

//fix language direction
//only add supported rtl languages to the array - all languages are ['ar','az','dv','ff','he','ku','fa','ur']
const rtl = [];
const base_lang = navigator.language.substring(0, 2).toLowerCase();
let cssFile = "ltr.css";
if (rtl.indexOf(base_lang) > -1) {
  cssFile = "rtl.css";
}
import(`./css/${cssFile}`);

function App() {
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.authentication.value);

  ////////// INITIALIZE /////////
  const [anonPage, setAnonPage] = useState("yongo");
  const [loading, setLoading] = useState(true);
  const [language, setLanguage] = useState(translator.getTranslator());
  const [pageWidth, setPageWidth] = useState(window.innerWidth);
  const [pageHeight, setPageHeight] = useState(window.innerHeight);
  const [os, setOs] = useState("desktop");

  const main_focus = useRef();
  const _main_ = useRef();

  useEffect(() => {
    // initializeGui();

    checkUser();
    setLoading(false);

    //detect operating system
    setOs(getOs);

    //detect window size fit side menu
    function handleResize() {
      setPageWidth(window.innerWidth);
      setPageHeight(window.innerHeight);
    }
    window.addEventListener("resize", handleResize);

    //navigator language change
    function handleLanguage() {
      setLanguage(translator.getTranslator());
    }
    window.addEventListener("languagechange", handleLanguage);

    return (_) => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("languagechange", handleLanguage);
    };
  }, []);

  useEffect(() => {
    //localize SEO
    document.documentElement.lang = language.lang;
    document.title = language.labels.brand.web;
    const metas = document.getElementsByTagName("meta");
    for (let i = 0; i < metas.length; i++) {
      if (metas[i].getAttribute("name") === "description") {
        metas[i].setAttribute("content", language.labels.brand.description);
      }
      if (metas[i].getAttribute("property") === "og:title") {
        metas[i].setAttribute("content", language.labels.brand.web);
      }
      if (metas[i].getAttribute("property") === "og:description") {
        metas[i].setAttribute("content", language.labels.brand.description);
      }
    }
  }, [language]);

  ////////// OPERATING SYSTEM /////////
  const getOs = () => {
    var userAgent = navigator.userAgent;
    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "desktop";
    }
    if (/android/i.test(userAgent)) {
      return "android";
    }
    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/ipad|iphone|ipod/i.test(userAgent)) {
      return "ios";
    }
    return "desktop";
  };

  ////////// SET PAGE /////////

  //check that user hasn't timed out
  const checkUser = async () => {
    let cookie = cookies.get("yongo");
    if (localStorage.getItem("last_updated") && cookie) {
      if (
        Date.now() - localStorage.getItem("last_updated") <=
        config.server.timeout
      ) {
        if (
          !auth.account_type &&
          localStorage.getItem("auth") &&
          localStorage.getItem("basic")
        ) {
          //auth and basic
          dispatch(login(JSON.parse(localStorage.getItem("auth"))));
          dispatch(setBasic(JSON.parse(localStorage.getItem("basic"))));

          //communities
          if (localStorage.getItem("communities")) {
            dispatch(
              setCommunities(JSON.parse(localStorage.getItem("communities")))
            );
          } else {
            dispatch(unsetCommunities());
          }

          //context
          if (localStorage.getItem("context")) {
            dispatch(setContext(JSON.parse(localStorage.getItem("context"))));
          } else {
            dispatch(unsetContext());
          }

          //emails
          if (localStorage.getItem("emails")) {
            dispatch(setEmails(JSON.parse(localStorage.getItem("emails"))));
          } else {
            dispatch(unsetEmails());
          }
        }
      } else {
        signout();
      }
    } else {
      signout("yongo");
    }
  };

  //get the policy docs
  const checkPolicies = async () => {
    const configurationObject = {
      url: `${config.server.api + "/policies/hub"}`,
      method: "POST",
      data: { lang: language.lang },
      withCredentials: true,
      credentials: "include",
    };
    try {
      let policies = await axios(configurationObject);
      if (policies.status === 200) {
        return policies.data;
      } else {
        return [];
      }
    } catch (err) {
      return [];
    }
  };

  ////////// FUNCTIONS /////////
  const signout = async (page = "timedout") => {
    setAnonPage(page);
    cookies.remove("yongo");
    let client_id = localStorage.getItem("client_id") || "";

    localStorage.clear();
    sessionStorage.removeItem("invitation");
    if (client_id) await logoff(client_id);

    dispatch(logout());
    dispatch(unsetBasic());
  };

  ////////// ROUTES /////////
  return (
    <>
      {loading ? (
        <div
          className="loader"
          style={{
            top: `${pageHeight / 2 - 46 + "px"}`,
            left: `${pageWidth / 2 - 46 + "px"}`,
            textAlign: "center",
          }}
        >
          <Loader
            color={"blue"}
            loading={true}
            aria-label={language.labels.aria.loading}
            size={80}
          />
          <div
            style={{
              opacity: "0.7",
              position: "absolute",
              top: "38px",
              width: "92px",
              textAlign: "center",
              fontSize: "12px",
            }}
          >
            {language.labels.aria.loading}
          </div>
        </div>
      ) : (
        <>
          <div id="loader" style={{ zIndex: 10 }}>
            <Loader
              color={"blue"}
              loading={true}
              cssOverride={{
                marginTop: `${pageHeight / 2 - 46 + "px"}`,
                marginLeft: `${pageWidth / 2 - 46 + "px"}`,
              }}
              aria-label={language.labels.aria.loading}
              size={80}
            />
            <div
              style={{
                position: "absolute",
                opacity: "0.7",
                top: `${pageHeight / 2 - 8 + "px"}`,
                left: `${pageWidth / 2 - 46 + "px"}`,
                width: "92px",
                textAlign: "center",
                fontSize: "12px",
              }}
            >
              {language.labels.aria.loading}
            </div>
          </div>
          <Router>
            {auth.account_type &&
            auth.account_type !== "suspended" &&
            auth.agree_terms === "ok" ? (
              <Content
                language={language}
                signout={signout}
                pageWidth={pageWidth}
                _main_={_main_}
                main_focus={main_focus}
                os={os}
              >
                <Routes>
                  {/* Hub Redirection Routes */}
                  <Route path="*" element={<Navigate to="/hub" />} />
                  <Route
                    path="/gotoapp"
                    element={<Deeplink language={language} os={os} />}
                  />
                  <Route
                    path="/admin"
                    element={
                      <Admin
                        language={language}
                        signout={signout}
                        main_focus={main_focus}
                      />
                    }
                  />
                  <Route path="/hub" element={<Hub />} />
                  <Route
                    path="/account_deletion"
                    element={
                      <AccountDelete language={language} signout={signout} />
                    }
                  />
                  <Route
                    path="/invitation"
                    element={
                      <Invitation language={language} signout={signout} />
                    }
                  />
                  <Route path="/ref" element={<Redirect />} />
                  <Route path="/sso" element={<Sso language={language} />} />
                  <Route
                    path="/vid"
                    element={
                      <Vid
                        language={language}
                        signout={signout}
                        pageHeight={pageHeight}
                      />
                    }
                  />

                  {/* Public Hub Routes */}
                  <Route
                    path="/community"
                    element={<PubCommunity language={language} os={os} />}
                  />
                  {/* <Route
                    path="/find_community"
                    element={<Find language={language} />}
                  /> */}
                  <Route
                    path="/privacy"
                    element={
                      <Policy
                        language={language}
                        checkPolicies={checkPolicies}
                      />
                    }
                  />

                  <Route
                    path="/support"
                    element={
                      <Support
                        language={language}
                        checkPolicies={checkPolicies}
                      />
                    }
                  />
                  <Route
                    path="/terms_conditions"
                    element={
                      <Policy
                        language={language}
                        checkPolicies={checkPolicies}
                        policy="TC"
                      />
                    }
                  />
                  <Route
                    path="/yongo"
                    element={<Yongo language={language} os={os} />}
                  />

                  {/* Common Hub Routes */}
                  <Route
                    path="/account"
                    element={
                      <Account
                        language={language}
                        signout={signout}
                        checkPolicies={checkPolicies}
                        main_focus={main_focus}
                      />
                    }
                  />

                  {/* Hub Only Routes */}
                  <Route
                    path="/communityhub"
                    element={
                      <CommunityHub
                        language={language}
                        signout={signout}
                        main_focus={main_focus}
                      />
                    }
                  />
                  <Route
                    path="/flagged"
                    element={
                      <Flagged
                        language={language}
                        signout={signout}
                        main_focus={main_focus}
                      />
                    }
                  />
                  <Route
                    path="/answerFlag"
                    element={
                      <AnswerFlag
                        language={language}
                        signout={signout}
                        main_focus={main_focus}
                      />
                    }
                  />
                  <Route
                    path="/directory"
                    element={
                      <Directory
                        language={language}
                        signout={signout}
                        main_focus={main_focus}
                      />
                    }
                  />
                  <Route
                    path="/invites"
                    element={
                      <Invites
                        language={language}
                        signout={signout}
                        main_focus={main_focus}
                      />
                    }
                  />
                  <Route
                    path="/knowledge"
                    element={
                      <Knowledge
                        language={language}
                        main_focus={main_focus}
                        pageHeight={pageHeight}
                        pageWidth={pageWidth}
                        signout={signout}
                      />
                    }
                  />
                  <Route
                    path="/livestream"
                    element={
                      <LiveStream
                        language={language}
                        signout={signout}
                        main_focus={main_focus}
                      />
                    }
                  />
                  <Route
                    path="/livestreams"
                    element={
                      <LiveStreams
                        language={language}
                        signout={signout}
                        main_focus={main_focus}
                      />
                    }
                  />
                  <Route
                    path="/mycommunity"
                    element={
                      <Context
                        language={language}
                        signout={signout}
                        main_focus={main_focus}
                      />
                    }
                  />
                  <Route
                    path="/newbite"
                    element={
                      <NewBlock
                        language={language}
                        signout={signout}
                        main_focus={main_focus}
                      />
                    }
                  />
                  <Route
                    path="/newbites"
                    element={
                      <NewBlocks
                        language={language}
                        signout={signout}
                        main_focus={main_focus}
                      />
                    }
                  />
                  <Route
                    path="/newpathway"
                    element={
                      <NewPathway
                        language={language}
                        signout={signout}
                        main_focus={main_focus}
                      />
                    }
                  />
                  <Route
                    path="/newcommunity"
                    element={
                      <NewCommunity
                        checkPolicies={checkPolicies}
                        language={language}
                        signout={signout}
                        main_focus={main_focus}
                      />
                    }
                  />
                  <Route
                    path="/newknowledge"
                    element={
                      <NewKnowledge
                        language={language}
                        signout={signout}
                        main_focus={main_focus}
                      />
                    }
                  />
                  <Route
                    path="/newvideo"
                    element={
                      <NewVideo
                        language={language}
                        signout={signout}
                        main_focus={main_focus}
                      />
                    }
                  />
                  <Route
                    path="/pathway"
                    element={
                      <Pathway
                        language={language}
                        signout={signout}
                        main_focus={main_focus}
                      />
                    }
                  />
                  <Route
                    path="/pathways"
                    element={
                      <Pathways
                        language={language}
                        signout={signout}
                        main_focus={main_focus}
                      />
                    }
                  />
                  <Route
                    path="/pathwaystream"
                    element={
                      <PathwayStream
                        language={language}
                        signout={signout}
                        main_focus={main_focus}
                        os={os}
                      />
                    }
                  />
                  <Route
                    path="/personnel"
                    element={
                      <Managers
                        language={language}
                        signout={signout}
                        main_focus={main_focus}
                      />
                    }
                  />
                  <Route
                    path="/pubstream"
                    element={
                      <LivePublish language={language} signout={signout} />
                    }
                  />
                  <Route
                    path="/theme"
                    element={
                      <Topics
                        language={language}
                        signout={signout}
                        main_focus={main_focus}
                      />
                    }
                  />
                  <Route
                    path="/thread"
                    element={
                      <Thread
                        language={language}
                        signout={signout}
                        main_focus={main_focus}
                      />
                    }
                  />
                  <Route
                    path="/bites"
                    element={
                      <Blocks
                        language={language}
                        main_focus={main_focus}
                        pageHeight={pageHeight}
                        pageWidth={pageWidth}
                        signout={signout}
                      />
                    }
                  />
                  <Route
                    path="/bite"
                    element={
                      <BlockDetails
                        language={language}
                        signout={signout}
                        main_focus={main_focus}
                      />
                    }
                  />
                  <Route
                    path="/video"
                    element={
                      <Video
                        language={language}
                        signout={signout}
                        main_focus={main_focus}
                      />
                    }
                  />
                  <Route
                    path="/videos"
                    element={
                      <VideoHub
                        language={language}
                        main_focus={main_focus}
                        pageHeight={pageHeight}
                        pageWidth={pageWidth}
                        signout={signout}
                      />
                    }
                  />
                  <Route
                    path="/welcome"
                    element={
                      <Welcome
                        language={language}
                        os={os}
                        main_focus={main_focus}
                      />
                    }
                  />
                </Routes>
              </Content>
            ) : auth.account_type &&
              auth.account_type !== "suspended" &&
              auth.agree_terms !== "ok" ? (
              // Agree Terms
              <Routes>
                <Route path="*" element={<Navigate to={`/terms`} />} />
                <Route path="/fwd" element={<Redirect />} />
                <Route path="/sso" element={<Sso language={language} />} />
                <Route
                  path="/terms"
                  element={
                    <Terms
                      language={translator.getTranslator()}
                      signout={signout}
                      checkPolicies={checkPolicies}
                    />
                  }
                />
                {/* <Route
                  path="/vid"
                  element={
                    <Vid
                      language={language}
                      signout={signout}
                      pageHeight={pageHeight}
                    />
                  }
                /> */}
              </Routes>
            ) : auth.account_type ? (
              // Suspended
              <Routes>
                <Route
                  path="*"
                  element={
                    <Suspended
                      language={language}
                      checkPolicies={checkPolicies}
                    />
                  }
                />
              </Routes>
            ) : (
              //Public
              <Routes>
                <Route path="*" element={<Navigate to={`/${anonPage}`} />} />
                {/* <Route
                  path="/benefits"
                  element={<Benefits language={language} />}
                /> */}
                <Route
                  path="/community"
                  element={<PubCommunity language={language} os={os} />}
                />
                {/* <Route
                  path="/find_community"
                  element={<Find language={language} />}
                /> */}
                <Route
                  path="/forgot"
                  element={<Forgot language={language} />}
                />
                <Route path="/pwd" element={<Password language={language} />} />
                <Route path="/ref" element={<Redirect />} />
                {/* <Route
                  path="/getyongo"
                  element={<Get language={language} os={os} />}
                /> */}
                <Route
                  path="/account_deletion"
                  element={<AccountDelete language={language} />}
                />
                <Route
                  path="/invitation"
                  element={<Invitation language={language} signout={signout} />}
                />
                <Route
                  path="/login"
                  element={
                    <Login language={language} checkPolicies={checkPolicies} />
                  }
                />
                <Route
                  path="/privacy"
                  element={
                    <Policy language={language} checkPolicies={checkPolicies} />
                  }
                />

                <Route
                  path="/support"
                  element={
                    <Support
                      language={language}
                      checkPolicies={checkPolicies}
                    />
                  }
                />
                <Route
                  path="/terms_conditions"
                  element={
                    <Policy
                      language={language}
                      checkPolicies={checkPolicies}
                      policy="TC"
                    />
                  }
                />
                <Route
                  path="/signup"
                  element={
                    <Signup language={language} checkPolicies={checkPolicies} />
                  }
                />
                <Route path="/sso" element={<Sso language={language} />} />
                <Route
                  path="/timedout"
                  element={<TimedOut language={language} />}
                />
                <Route
                  path="/vid"
                  element={
                    <Vid
                      language={language}
                      signout={signout}
                      pageHeight={pageHeight}
                    />
                  }
                />
                <Route
                  path="/yongo"
                  element={<Yongo language={language} os={os} />}
                />
              </Routes>
            )}
          </Router>
        </>
      )}
    </>
  );
}

export default App;
