import React, { useState, useEffect } from "react";
import { Tooltip } from "react-tooltip";

import { naturalDate } from "../../../../lib/date";
import validator from "../../../../lib/validation";
import axiosCall from "../../../../lib/axios";

import Modal from "../../../modal/Modal";

import config from "../../../../config";

const Preview = ({
  language,
  animation,
  duplicates,
  duplicatesInc,
  community,
  next,
  skip,
  navigate,
  signout,
  props,
}) => {
  //////////// INITIALIZATION ///////////
  const [notice, setNotice] = useState(language.labels.bites.similar.one);
  const [alternative, setAlternative] = useState(null);
  const [newTopic, setNewTopic] = useState(
    language.labels.bites.potential.video.many
  );

  const [showModal, setShowModal] = useState(-1);
  const [modalMessage, setModalMessage] = useState("");
  const [pathwayModal, setPathwayModal] = useState(false);
  const [block, setBlock] = useState({});
  const [pids, setPids] = useState([]);

  useEffect(() => {
    //animation
    if (animation && animation.step === 1) {
      if (animation.direction === 1) {
        document.getElementById("animatePreview").style.animationName =
          "slidein";
      } else if (animation.direction === -1) {
        document.getElementById("animatePreview").style.animationName =
          "slideout";
        document.getElementById("animatePreview").style.animationDirection =
          "reverse";
      }
    }

    //labels
    let qty = "one";
    if (duplicates.length > 1) qty = "many";
    if (props.preEmpt) {
      if (duplicates.length > 1) {
        setNotice(
          language.labels.bites.potential.many.replace(
            /{n}/g,
            duplicates.length
          )
        );
      } else {
        setNotice(language.labels.bites.potential.one);
      }
      if (props.referredBy === "knowledge") {
        setNewTopic(language.labels.bites.potential.knowledge[qty]);
      } else {
        setNewTopic(language.labels.bites.potential.video[qty]);
      }
    } else {
      if (!props.topicId && props.communityPathways.length > 0)
        setAlternative(language.labels.bites.alternative_pathways[qty]);

      if (props.equivalent) {
        setNotice(language.labels.bites.equivalent_one);
      } else if (duplicates.length > 1) {
        setNotice(
          language.labels.bites.similar.many.replace(/{n}/g, duplicates.length)
        );
      }
    }

    //block pathways
    if (!props.topicId && !props.gotPathways && !props.preEmpt) {
      get_community_pathways();
    }
  }, []);

  useEffect(() => {
    if (block._id) {
      if (props.topicId) {
        add_pathway();
      } else {
        setPathwayModal(true);
      }
    }
  }, [block]);

  useEffect(() => {
    if (pathwayModal) {
      let _pids = new Array();
      let checked = props.bitePathways.find((bp) => bp.block === block._id);

      if (checked) {
        let checks = document.getElementsByName("pathway_selector");
        for (var i = 0; i < checks.length; i++) {
          if (checked.pathways.includes(checks[i].value)) {
            _pids.push(
              checked.pathways[checked.pathways.indexOf(checks[i].value)]
            );
            checks[i].checked = true;
          }
        }
      }
      setPids(_pids);
    }
  }, [pathwayModal]);

  ////////// STEPS ///////////
  const back = () => {
    document.getElementById("animatePreview").style.animationName = "slidein";
    document.getElementById("animatePreview").style.animationDirection =
      "reverse";
    next({ step: 0, direction: -1 });
  };

  const proceed = async () => {
    document.getElementById("animatePreview").style.animationName = "slideout";
    next({ step: 2, direction: 1 });
  };

  const rework = async () => {
    let ignored = new Array();
    duplicates.forEach((duplicate) => ignored.push(duplicate._id));
    props.setIgnored(ignored);
    props.setPreEmpt(false);
    props.setPageHeading(language.labels.bites.add_one);
    document.getElementById("animatePreview").style.animationName = "slideout";
    next({ step: 0, direction: 1 });
  };

  ////////// ADD PATHWAYS ///////////
  const get_community_pathways = async () => {
    let pathways = await axiosCall("community/pathways");
    if (pathways.success) {
      props.setCommunityPathways(pathways.data.pathways);
      props.setGotPathways(true);
    } else if (pathways.refresh) {
      //token has been refreshed, try again
      get_community_pathways();
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  const modifyPathways = async () => {
    // get selections
    let additions = new Array();
    let subtractions = new Array();
    const pathway_selector = document.getElementsByName("pathway_selector");
    for (var i = 0; i < pathway_selector.length; i++) {
      if (pathway_selector[i].checked) {
        if (!pids.includes(pathway_selector[i].value))
          additions.push(pathway_selector[i].value);
      } else {
        if (pids.includes(pathway_selector[i].value))
          subtractions.push(pathway_selector[i].value);
      }
    }

    if (additions.length + subtractions.length > 0) {
      document.getElementById("loader").style.display = "inline";
      const data = {
        bid: block._id,
        thumb: block.thumb,
        additions,
        subtractions,
      };

      let result = await axiosCall("block/pathways", data);
      if (result.success) {
        props.success(block._id, "animatePreview", true);
      } else if (result.refresh) {
        //token has been refreshed, try again
        modifyPathways();
      } else {
        //refresh token expired or unknown error
        signout();
      }
    } else {
      //no changes
      props.success(block._id, "animatePreview", true);
    }
  };

  const cancelPathways = () => {
    setBlock({});
    setPathwayModal(false);
  };

  ////////// ADD PATHWAY ///////////
  const add_to_pathway = (index) => {
    if (duplicates[index].community === community.id) {
      setBlock(duplicates[index]);
    } else {
      let lbl = language.labels.pathways.third_party.pathways;
      if (props.topicId) lbl = language.labels.pathways.third_party.pathway;
      setModalMessage(lbl.replace(/{entity}/g, duplicates[index].poster_name));
      setShowModal(index);
    }
  };

  const canModal = () => {
    setBlock({});
    setModalMessage("");
    setShowModal(-1);
  };

  const okModal = () => {
    if (props.referredBy) {
      navigate("/bite", {
        state: { bid: duplicates[showModal]._id, referredBy: props.referredBy },
      });
    } else {
      setBlock(duplicates[showModal]);
    }
    setShowModal(-1);
  };

  const add_pathway = async () => {
    const data = {
      bid: block._id,
      thumb: block.thumb,
      additions: [props.topicId],
    };

    let result = await axiosCall("block/pathways", data);
    if (result.success) {
      skip("animatePreview");
      // next({ step: 3, direction: 1 });
    } else if (result.refresh) {
      //token has been refreshed, try again
      add_pathway();
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  ////////// ADD CONTENT ///////////
  const add_content = (index) => {
    if (duplicates[index].community === community.id) {
      navigate("/bite", {
        state: { bid: duplicates[index]._id, referredBy: props.referredBy },
      });
    } else {
      setModalMessage(
        language.labels.pathways.third_party[props.referredBy].replace(
          /{entity}/g,
          duplicates[index].poster_name
        )
      );
      setShowModal(index);
    }
  };

  ////////////// RENDER GUI //////////////////
  const mappedBlocks = duplicates.map((block, index) => (
    <div
      role="figure"
      aria-label={block.title}
      key={index}
      className="card-long"
    >
      <div
        style={{
          backgroundImage: `url(${config.content.server + block.thumb})`,
          flexShrink: "0",
        }}
        className="thumb-block auto-margin-narrow"
      ></div>
      <div className="block-info">
        {/* title, date and poster */}
        <div className="block-title">{block.title}</div>
        <div style={{ fontSize: "0.8em", margin: "0.2em 0" }}>
          {naturalDate(
            block.last_updated,
            language.locale,
            language.labels.date
          )}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "6px",
            margin: "0.5em 0",
          }}
        >
          <div
            className="avatar small"
            style={{
              flexShrink: "0",
              backgroundImage: `url(${
                config.content.server + block.poster_image
              })`,
              borderRadius: "6px",
            }}
          ></div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "calc(100% - 30px)",
            }}
          >
            <div className="name" style={{ fontSize: "1em" }}>
              {block.poster_name}
            </div>
          </div>
        </div>

        {/* meta */}
        <div
          style={{
            display: "flex",
            marginTop: "0.5em",
            alignItems: "center",
            opacity: "0.8",
          }}
        >
          <div
            role="img"
            className="glyphs"
            style={{ flexBasis: "1.5em", fontSize: "0.9em" }}
            aria-label={language.labels.aria.followers}
            data-tooltip-id="followers-tooltip"
            data-tooltip-content={language.labels.aria.bookmarks}
          >
            q
          </div>
          <div style={{ fontSize: "0.8em" }}>
            {block.count_bookmarks
              ? validator.bigNumber(block.count_bookmarks)
              : 0}
          </div>
          <div style={{ flexBasis: "2em" }}></div>

          <div
            role="img"
            className="glyphs"
            style={{ flexBasis: "1.5em", fontSize: "0.9em" }}
            aria-label={language.labels.navigation.videos}
            data-tooltip-id="videos-tooltip"
            data-tooltip-content={language.labels.navigation.videos}
          >
            v
          </div>
          <div style={{ fontSize: "0.8em" }}>
            {validator.bigNumber(block.count_video)}
          </div>
          <div style={{ flexBasis: "2em" }}></div>
          <div
            role="img"
            className="glyphs"
            style={{ flexBasis: "1.5em", fontSize: "0.9em" }}
            aria-label={language.labels.navigation.knowledge}
            data-tooltip-id="knowledge-tooltip"
            data-tooltip-content={language.labels.navigation.knowledge}
          >
            k
          </div>
          <div style={{ fontSize: "0.8em" }}>
            {validator.bigNumber(block.count_knowledge)}
          </div>
        </div>

        {/* about */}
        <div style={{ fontSize: ".9em", fontWeight: "500", marginTop: "1em" }}>
          {language.labels.bites.description.about}
        </div>
        <div style={{ fontSize: ".9em" }}>{block.about}</div>

        {/* extras */}
        {block.extra.map((extra) => (
          <div style={{ fontSize: ".9em", marginTop: "0.75em" }}>
            <div style={{ fontWeight: "500" }}>{extra.heading}</div>
            <div>{extra.description}</div>
          </div>
        ))}

        {/* add to pathway */}
        {!props.referredBy &&
          !duplicatesInc &&
          (props.communityPathways.length > 0 || props.topicId) && (
            <button
              className="button"
              style={{
                marginTop: "0.5em",
                fontSize: "0.9em",
                alignSelf: "flex-end",
              }}
              onClick={() => add_to_pathway(index)}
            >
              {props.topicId
                ? language.labels.pathways.add.to_this
                : language.labels.pathways.add.to}
            </button>
          )}

        {!props.referredBy && props.topicId && duplicatesInc && (
          <>
            {duplicatesInc.some((obj) => obj["block"] === block._id) ? (
              <div
                className="alert"
                style={{
                  marginTop: "1em",
                  alignSelf: "flex-end",
                }}
              >
                {language.labels.pathways.add.already}
              </div>
            ) : (
              <button
                className="button"
                style={{
                  marginTop: "0.5em",
                  fontSize: "0.9em",
                  alignSelf: "flex-end",
                }}
                onClick={() => add_to_pathway(index)}
              >
                {language.labels.pathways.add.to_this}
              </button>
            )}
          </>
        )}

        {/* add content */}
        {props.referredBy && (
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              marginTop: "0.5em",
              fontSize: "0.9em",
            }}
          >
            <button className="button" onClick={() => add_content(index)}>
              {props.referredBy === "knowledge"
                ? language.labels.knowledge.add
                : language.labels.videos.add}
            </button>
          </div>
        )}
      </div>
    </div>
  ));

  const pathwaySelector = props.communityPathways.map((pwy, index) => (
    <div
      key={index}
      style={{
        display: "flex",
        gap: "0.5em",
        marginTop: "0.5em",
        alignItems: "flex-start",
      }}
    >
      <input
        name="pathway_selector"
        id={`pwy_${index}`}
        value={pwy._id}
        type="checkbox"
        // defaultChecked={pids.includes(pwy._id)}
      />
      <label className="hover" htmlFor={`pwy_${index}`}>
        {pwy.pathway}
      </label>
    </div>
  ));

  return (
    <div style={{ position: "relative" }}>
      <div
        id="animatePreview"
        className="block-step page-section"
        style={{ maxWidth: "66rem" }}
      >
        {props.vague ? (
          <>
            {/* alert */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "1.1em",
                marginBottom: "0.5em",
              }}
            >
              <div
                className="glyphs alert"
                style={{ fontSize: "1.1em", flexBasis: "1.5em" }}
              >
                !
              </div>
              <div>{language.labels.bites.vague}</div>
            </div>

            {/* blurb */}
            <div>{language.labels.bites.vague_description}</div>

            {/* back */}
            <div style={{ display: "flex", margin: "1em 0" }}>
              <button className="button-secondary" onClick={() => back()}>
                {language.labels.app.action_back}
              </button>
            </div>
          </>
        ) : (
          <>
            {/* alert */}
            {!props.preEmpt && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "1.1em",
                  marginBottom: "0.5em",
                }}
              >
                <div
                  className="glyphs alert"
                  style={{ fontSize: "1.1em", flexBasis: "1.5em" }}
                >
                  !
                </div>
                <div>
                  {props.equivalent
                    ? language.labels.bites.equivalent
                    : language.labels.bites.similar.bite}
                </div>
              </div>
            )}

            {/* blurb */}
            {notice && <div>{notice}</div>}

            {/* back / proceed */}
            {!props.preEmpt && (
              <div
                style={{ display: "flex", margin: "1.5em 0", gap: "0.25em" }}
              >
                {(props.equivalent || props.communityPathways.length < 1) && (
                  <button className="button-secondary" onClick={() => back()}>
                    {language.labels.app.action_back}
                  </button>
                )}
                {!props.equivalent && (
                  <button
                    className="button"
                    style={{ borderWidth: "2px" }}
                    onClick={() => proceed()}
                  >
                    {language.labels.bites.create_new}
                  </button>
                )}
              </div>
            )}
            {!props.referredBy && alternative && (
              <div style={{ marginTop: "0.5em" }}>{alternative}</div>
            )}

            {/* create new topic */}
            {props.preEmpt && (
              <>
                <div style={{ marginTop: "0.5em" }}>{newTopic}</div>
                <div
                  style={{ display: "flex", margin: "1em 0", gap: "0.25em" }}
                >
                  <button
                    className="button"
                    style={{ borderWidth: "2px" }}
                    onClick={() => rework()}
                  >
                    {language.labels.bites.create_new}
                  </button>
                </div>
                <div style={{ margin: "1em 0 -0.5em", fontWeight: 500 }}>
                  {duplicates.length > 1
                    ? language.labels.bites.match_many
                    : language.labels.bites.match_one}
                </div>
              </>
            )}

            {mappedBlocks}
          </>
        )}
      </div>

      {/* Confirm Modal */}
      <Modal
        className="modal-dynamic"
        title={language.labels.bites.third_party}
        show={showModal < 0 ? false : true}
      >
        <div>{modalMessage}</div>
        <div style={{ display: "flex", gap: "0.25em", marginTop: "2em" }}>
          <button className="button-off" onClick={() => canModal()}>
            {language.labels.app.cancel}
          </button>
          <button className="button" onClick={() => okModal()}>
            {language.labels.app.yes_sure}
          </button>
        </div>
      </Modal>

      {/* pathway modal */}
      <Modal
        children={
          props.communityPathways.length > 0 ? (
            <div>
              <div style={{ paddingBottom: "0.5em", fontSize: "0.9em" }}>
                {language.labels.bites.pathways.modify}
              </div>
              {pathwaySelector}
            </div>
          ) : (
            <div>{language.labels.bites.pathways.none}</div>
          )
        }
        className="modal-dynamic"
        title={language.labels.bites.pathways.add}
        show={pathwayModal}
        onClose={cancelPathways}
        onOk={
          props.communityPathways.length > 0 ? modifyPathways : cancelPathways
        }
        language={language}
      ></Modal>

      {/* tooltips */}
      <Tooltip id="followers-tooltip" />
      <Tooltip id="videos-tooltip" />
      <Tooltip id="knowledge-tooltip" />
    </div>
  );
};

export default Preview;
