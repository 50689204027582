import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import KnowledgeThread from "./thread";
import AddAnswer from "./answer";
import Modal from "../../modal/Modal";
import axiosCall from "../../../lib/axios";
import Card from "../../../lib/previewLinks";
import { naturalDate } from "../../../lib/date";
import config from "../../../config";

const KnowledgeViewer = ({
  community,
  style,
  language,
  currentThread,
  flagged = false,
  report = {},
  onClose,
  onChange,
  onDelete,
  removedAnswer,
  bite,
  signout,
}) => {
  //////////// INITIALIZATION ///////////
  const [thread, setThread] = useState({});
  const [answers, setAnswers] = useState([]);
  const [HTML, setHTML] = useState("");
  const [addAnswer, setAddAnswer] = useState(false);
  const [editor, setEditor] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [confirmClosure, setConfirmClosure] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(report?.status || "");
  const [status, setStatus] = useState("");
  const [actions, setActions] = useState([]);
  const [success, setSuccess] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    getThread();
  }, []);

  useEffect(() => {
    switch (currentStatus) {
      case "accepted":
        setActions(["restore"]);
        break;
      case "pending":
        setActions(["reject", "remove"]);
        break;
      case "rejected":
        setActions(["pending"]);
        break;
      default:
        setActions(["pending", "reject", "remove"]);
        break;
    }
  }, [currentStatus]);

  useEffect(() => {
    if (removedAnswer) {
      let _answers = new Array();
      for (var i = 0; i < answers.length; i++) {
        if (answers[i]._id !== removedAnswer) _answers.push(answers[i]);
        if (i === answers.length - 1) setAnswers(_answers);
      }
    }
  }, [removedAnswer]);

  //////////// THREAD ///////////
  const getThread = async () => {
    const data = { kid: currentThread };

    if (flagged) data["reporter"] = report.reporter;
    console.log("DATA", data);
    let result = await axiosCall("knowledge/thread", data);
    if (result.success) {
      if (result.data?.main) {
        setThread(result.data.main);
        if (result.data.answers) setAnswers(result.data.answers);
        fixHTML(result.data.main);
      } else {
        onClose();
      }
    } else if (result.refresh) {
      //token has been refreshed, try again
      getThread();
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  const processLinksMeta = async (meta, post, obj, card, processed) => {
    let _post = post;
    for (const _obj of meta) {
      if (_obj.link === obj.link) {
        processed.push(obj.link);
        post = post.replaceAll(obj.link, card);
        _post = _post.replaceAll(obj.link, card);
      } else if (processed.indexOf(_obj.link) < 0) {
        post = post.replaceAll(_obj.link, _obj.card);
      }
    }
    return { post, _post, processed };
  };

  const updateLinksMeta = async (meta, post) => {
    let processed = [];
    for (const obj of meta) {
      const result = await axiosCall(
        "knowledge/preview/link",
        { link: obj.link },
        false
      );
      const card = await Card({ ...result.data, link: obj.link });
      const p = await processLinksMeta(meta, post, obj, card, processed);
      processed = p.processed;
      post = p._post;
      setHTML(p.post);
    }
  };

  const fixHTML = async (data) => {
    if (data.links && data.links.length > 0) {
      let html = data.post;
      let meta = [];
      for (const link of data.links) {
        const card = await Card({ link });
        meta.push({ link, card });
        html = html.replaceAll(link, card);
      }
      setHTML(html);
      updateLinksMeta(meta, data.post);
    } else {
      setHTML(data.post);
    }
  };

  const deleteThread = async () => {
    let result = await axiosCall("knowledge/withdraw", {
      kid: currentThread,
    });
    if (result.success) {
      if (flagged) {
        setStatus("");
        setCurrentStatus("accepted");
        navigate("/flagged");
      } else {
        setDeleteModal(false);
        onDelete();
      }
    } else if (result.refresh) {
      //token has been refreshed, try again
      deleteThread();
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  const navBite = () => {
    navigate("/bite", {
      state: { bid: thread.block, thread: thread },
    });
  };

  const canModal = () => {
    setDeleteModal(false);
    if (flagged) {
      setStatus("");
    }
  };

  const takeAction = (action) => {
    if (action === "remove") {
      //do a popup that warns "are you sure etc..."
      //have an action on the modal so that if the admin cancels,
      setStatus("remove");
      setDeleteModal(true);
    } else {
      //change the status, update the page. stay on page
      setStatus(action);
      if (action === "reject" || action === "restore") {
        setCurrentStatus("rejected");
      } else {
        setCurrentStatus("pending");
      }
      updateStatus(action);
      setStatus("");
    }
  };

  const updateStatus = async (selectedStatus) => {
    let data = {
      kid: currentThread,
      action: selectedStatus,
    };

    let result = await axiosCall("report/settle/knowledge", data);
    if (result.success) {
      navigate(".", {
        state: {
          thread: {
            _id: currentThread,
          },
          flagged: true,
          report: { ...report, status: result.data },
        },
      });
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 3000);
    } else if (result.refresh) {
      //token has been refreshed, try again
      updateStatus(selectedStatus);
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  //////////// RENDER GUI ///////////
  const mapped_actions = actions.map((action, index) => {
    return (
      <option value={action} key={index}>
        {language.labels.flags.actions[action]}
      </option>
    );
  });

  if (thread._id) {
    return (
      <div className="thread" style={style}>
        {flagged && (
          <div className="page-section">
            <div
              style={{
                display: "flex",
                marginTop: "1em",
                alignItems: "center",
                gap: "1em",
              }}
            >
              <h2
                className="heading2"
                style={{ fontSize: "1.1em", fontWeight: "600" }}
              >
                {language.labels.flags.id.replace(/{id}/g, report._id)}
              </h2>
            </div>

            <div>{report.category}</div>

            <div className="quote-text" style={{ marginTop: "0.5em" }}>
              <div>{report.description}</div>
            </div>

            {/* poster */}
            <div className="heading2" style={{ marginTop: "1em" }}>
              {language.labels.flags.reported_by}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "4px",
                marginTop: "0.5em",
                maxWidth: "288px",
              }}
            ></div>

            <div
              className="avatar med"
              style={{
                flexShrink: "0",
                backgroundImage: `url(${
                  config.content.server + thread.reporter.avatar
                })`,
              }}
            ></div>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <div className="name" style={{ fontSize: "1em" }}>
                {thread.reporter.username}
              </div>

              <div style={{ fontSize: "0.7em", marginTop: "2px" }}>
                {naturalDate(
                  report.created,
                  language.locale,
                  language.labels.date
                )}
              </div>
            </div>

            <div style={{ marginTop: "1em", display: "flex", gap: "0.5em" }}>
              <div>{language.labels.app.status}</div>
              <div style={{ fontWeight: "500" }}>
                {language.labels.flags.status[currentStatus]}
              </div>
            </div>

            {success && (
              <div
                style={{ fontWeight: 600, marginTop: "1em" }}
                className="success"
                role="alert"
              >
                {language.labels.flags.success}
              </div>
            )}

            {/* OPTIONS */}
            <div style={{ marginTop: "1em" }}>
              <select
                className="large"
                aria-label={language.labels.roles.role_choose}
                value={status}
                onChange={(e) => {
                  takeAction(e.target.value);
                }}
              >
                <option value="" selected disabled hidden>
                  {language.labels.flags.take_action}
                </option>
                {mapped_actions}
              </select>
            </div>

            <div style={{ marginTop: "2.5em" }}>
              <div className="line"></div>
            </div>
          </div>
        )}

        {flagged && (
          <div
            role="link"
            className="page-section card-long hover"
            style={{ display: "block" }}
            onClick={navBite}
            onKeyUpCapture={(e) => e.key === "Enter" && navBite()}
            tabIndex={0}
          >
            <div style={{ marginBottom: "0.5em" }}>
              {language.labels.videos.bite}
            </div>
            <div
              style={{ display: "flex", alignItems: "center", width: "100%" }}
            >
              <div
                role="img"
                aria-label={thread.bite_name}
                className="menu-crest"
                style={{
                  width: "3em",
                  height: "3em",
                  backgroundImage: `url("${
                    config.content.server + thread.bite_image
                  }")`,
                }}
              ></div>
              <div style={{ width: "0.5em" }}></div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "calc(100% - 2.5em)",
                }}
              >
                <div
                  className="clamped one"
                  style={{
                    fontWeight: 500,
                    fontSize: "1.2em",
                    margin: "0.25em 0",
                  }}
                >
                  {thread.bite_name}
                </div>
                <div className="handle2" style={{ width: "100%" }}>
                  {community.title}
                </div>
              </div>
            </div>
          </div>
        )}
        {/* back */}
        {!editor._id && bite && (
          <button
            className="link"
            style={{ marginBottom: "1em" }}
            onClick={() => onClose()}
          >
            {language.labels.bites.knowledge_all}
          </button>
        )}

        <div style={{ marginTop: "0em" }}>
          <KnowledgeThread
            community={community}
            language={language}
            thread={thread}
            flagged={flagged}
            HTML={HTML}
            answers={answers}
            addAnswer={addAnswer}
            setAddAnswer={setAddAnswer}
            editor={editor}
            setEditor={setEditor}
            getThread={getThread}
            onChange={onChange}
            setDeleteModal={setDeleteModal}
            bite={bite}
            signout={signout}
          ></KnowledgeThread>

          {addAnswer && (
            <AddAnswer
              thread={thread}
              language={language}
              signout={signout}
              setAddAnswer={setAddAnswer}
              onChange={onChange}
              getThread={getThread}
            ></AddAnswer>
          )}
        </div>

        {/* DELETE KNOWLEDGE MODAL */}
        <Modal
          className="modal-dynamic"
          title={language.labels.knowledge.delete[thread.knowledge_type]}
          show={deleteModal}
        >
          <div
            style={{ fontSize: "1.1em" }}
            dangerouslySetInnerHTML={{
              __html: language.labels.app.delete_warning,
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              fontSize: ".9rem",
              marginTop: "1rem",
            }}
          >
            <input
              id="consequence_understood"
              type={"checkbox"}
              onChange={(e) => setConfirmClosure(e.target.checked)}
              defaultChecked={confirmClosure}
            />
            <div style={{ minWidth: "0.5rem" }}></div>
            <label className="hover" htmlFor="consequence_understood">
              {language.labels.app.consequences_understood}
            </label>
          </div>
          {confirmClosure && (
            <div style={{ display: "flex", marginTop: "0.5em" }}>
              <button className="button" onClick={() => deleteThread()}>
                {language.labels.knowledge.delete_now[thread.knowledge_type]}
              </button>
            </div>
          )}
          <div style={{ display: "flex", marginTop: "2em" }}>
            <button
              className="button-off"
              onClick={() => {
                canModal();
              }}
            >
              {language.labels.app.cancel}
            </button>
          </div>
        </Modal>
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default KnowledgeViewer;
