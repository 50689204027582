import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import axiosCall from "../../lib/axios";
import { naturalDate } from "../../lib/date";
import config from "../../config";

const LiveStreams = ({ language, main_focus, signout }) => {
  //////////// INITIALIZATION ///////////
  const context = useSelector((state) => state.context.value);
  const navigate = useNavigate();

  const recentRef = useRef();

  const [tab, setTab] = useState(null);
  const [recent, setRecent] = useState([]);

  useEffect(() => {
    setTab("recent");
  }, []);

  useEffect(() => {
    if (tab === "recent") getRecent();
  }, [tab]);

  //////////// DATA ///////////
  const getRecent = async () => {
    let result = await axiosCall("live/communitystreams", {
      community: context.id,
      filter: "recent_learning",
    });
    if (result.success) {
      setRecent(result.data);
    } else if (result.refresh) {
      //token has been refreshed, try again
      getRecent();
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  //////////// TAB INTERACTIONS ///////////
  function switchTab(e) {
    const tp = ["recent", "past"];
    var n = tp.indexOf(e.target.id);
    if (n > -1) {
      if (e.key === "ArrowRight") n++;
      if (e.key === "ArrowLeft") n--;
      if (n > -1 && n < tp.length) {
        selectTab(tp[n]);
        document.getElementById(tp[n]).focus();
      }
    }
  }

  function selectTab(tab) {
    console.log(tab);
    setTab(tab);
  }

  //////////// KEY NAVIGATION ///////////
  //navigate list with keys
  const bumpPos = (contents, direction) => {
    //find current position in list
    let pos = -1;
    var j = 0,
      len = contents.length;
    while (j < len) {
      if (contents[j] === document.activeElement) {
        pos = j;
        j = len;
      }
      j++;
    }
    pos = +pos + direction;
    if (pos < 0) pos = 0;
    if (pos >= len) pos = len - 1;
    contents[pos].focus();
  };
  const navResults = (e, container) => {
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      let direction = 1;
      if (e.key === "ArrowUp") direction = -1;
      let contents = container.querySelectorAll("div.card-long");
      bumpPos(contents, direction);
    }
  };

  //////////// ACTION ///////////
  const goToStream = (streamId) => {
    navigate("/livestream", { state: { streamId } });
  };

  //////////// RENDER GUI ///////////
  const mapped_recent = recent.map((stream, index) => {
    return (
      <div
        role="figure"
        className="card-long hover"
        key={index}
        onClick={() => goToStream(stream._id)}
        onKeyDown={(e) => navResults(e, recentRef.current)}
        tabIndex={0}
      >
        {/* publisher */}
        <div>
          <div
            style={{
              backgroundImage: `url(${config.content.server}${stream.pub_avatar})`,
              flexShrink: "0",
              borderRadius: "50%",
            }}
            className="thumb-block auto-margin-narrow"
          ></div>
          <div
            style={{
              fontWeight: "500",
              fontSize: "0.9em",
              textAlign: "center",
              marginTop: "2px",
            }}
          >
            {stream.pub_name}
          </div>
        </div>

        {/* details */}
        <div className="block-info">
          <div
            className="clamped one"
            style={{ fontWeight: "500", fontSize: "1.1em" }}
          >
            {stream.streamName}
          </div>

          <div style={{ fontSize: "0.8em", margin: "0.2em 0" }}>
            {stream.started
              ? language.labels.live.started.replace(
                  /{date}/g,
                  naturalDate(
                    stream.created,
                    language.locale,
                    language.labels.date
                  )
                )
              : language.labels.live.created.replace(
                  /{date}/g,
                  naturalDate(
                    stream.created,
                    language.locale,
                    language.labels.date
                  )
                )}
          </div>

          <div
            className="contrast clamped one"
            style={{ fontStyle: "italic", marginTop: "0.5em" }}
          >
            {stream.pathwayTitle}
          </div>

          {/* status */}
          {stream.ended ? (
            <div
              className="middle"
              style={{ fontWeight: 500, marginTop: "0.5em" }}
            >
              {language.labels.live.ended_time.replace(
                /{time}/g,
                naturalDate(stream.ended, language.locale, language.labels.date)
              )}
            </div>
          ) : (
            <div
              className={stream.started ? "alert" : "amber"}
              style={{ fontWeight: 500, margin: "0.5em 0" }}
            >
              {stream.started
                ? language.labels.live.streaming.now
                : language.labels.live.streaming.pending}
            </div>
          )}
        </div>
      </div>
    );
  });

  if (context.free_streams && context.role !== "role_member") {
    return (
      <main className="main-page" ref={main_focus} tabIndex={0}>
        <div className="page-section" style={{ display: "flex" }}>
          <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
            <div
              role="img"
              aria-label={language.labels.aria.logo.replace(
                /{org}/g,
                context.title
              )}
              className="menu-crest"
              style={{
                height: "3em",
                width: "3em",
                backgroundImage: `url("${
                  config.content.server + context.crest
                }")`,
              }}
            ></div>
            <div style={{ width: "0.5em" }}></div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "calc(100% - 2.5em)",
              }}
            >
              <h1 className="heading" style={{ width: "100%", padding: "0" }}>
                {language.labels.navigation.live_streams}
              </h1>
              <div className="handle2" style={{ width: "100%" }}>
                {context.title}
              </div>
            </div>
          </div>
        </div>

        <div className="page-section" style={{ maxWidth: "66rem" }}>
          <div style={{ marginTop: "0.5em" }} className="quote-text">
            Live learning streams are currently in <b>beta</b> and only
            available in the Community Hub to community experts and instructors.
            It will be become available to all community members in release 2.0
            of the Yongo App.
          </div>

          {/* new stream */}
          <button
            style={{
              alignSelf: "flex-start",
              marginTop: "0.5em",
              fontWeight: "500",
            }}
            className="button"
            onClick={() => {
              navigate("/pathwaystream");
            }}
          >
            {language.labels.live.start_new}
          </button>
        </div>

        <div className="page-section">
          {/* tab menu */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "1.5em",
              margin: "0.5em 0 1em 0",
            }}
            role="tablist"
          >
            <div
              role="tab"
              id="recent"
              className={`subtab ${tab !== "recent" && "off"}`}
              onClick={() => selectTab("recent")}
              aria-selected={tab === "recent" ? "true" : "false"}
              tabIndex={tab === "recent" ? "0" : "-1"}
              onKeyUpCapture={(e) => switchTab(e)}
            >
              <span>{language.labels.app.recent}</span>
              <div></div>
            </div>

            <div
              role="tab"
              id="past"
              className={`subtab ${tab !== "past" && "off"}`}
              onClick={() => selectTab("past")}
              aria-selected={tab === "past" ? "true" : "false"}
              tabIndex={tab === "past" ? "0" : "-1"}
              onKeyUpCapture={(e) => switchTab(e)}
            >
              <span>{language.labels.app.past}</span>
              <div></div>
            </div>
          </div>

          {/* recent tab */}
          {tab === "recent" && (
            <>
              {/* refresh */}
              <div
                style={{ margin: "1em 0 0.5em 0", display: "flex", gap: "1em" }}
              >
                <div>
                  {recent.length < 1
                    ? language.labels.live.recent.none
                    : `${
                        recent.length > 1
                          ? language.labels.live.recent.multiple.replace(
                              /{n}/g,
                              recent.length
                            )
                          : language.labels.live.recent.one
                      }`}
                </div>

                <button
                  aria-label={language.labels.aria.refresh}
                  className="glyphs success"
                  onClick={() => getRecent(true)}
                >
                  r
                </button>
              </div>
              <div
                ref={recentRef}
                style={{ maxWidth: "66rem", paddingBottom: "1em" }}
              >
                {mapped_recent}
              </div>
            </>
          )}
        </div>
      </main>
    );
  } else {
    return <></>;
  }
};

export default LiveStreams;
