import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { WebRTCAdaptor } from "@antmedia/webrtc_adaptor";

import config from "../../config";
import axiosCall from "../../lib/axios";
import { trueTime } from "../../lib/date";

const LivePublish = ({ language, signout }) => {
  ////////////// INITIALIZE //////////////////
  const { state } = useLocation();

  console.log(state);

  const userData = useSelector((state) => state.basic.value);

  const [publishing, setPublishing] = useState(false);
  const [dataChannel, setDataChannel] = useState(false);
  const [websocketConnected, setWebsocketConnected] = useState(false);
  const [activeCount, setActiveCount] = useState(0);

  const [streamName, setStreamName] = useState("");
  const [streamStarted, setStreamStarted] = useState("");
  const [bitesArr, setBitesArr] = useState([]);
  const [messages, setMessages] = useState([]);
  const [muted, setMuted] = useState(false);
  const [paused, setPaused] = useState(false);
  const [error, setError] = useState(null);

  const videoRef = useRef();
  const msgBox = useRef();
  const msgRef = useRef();
  const nameRef = useRef();
  const webRTCAdaptor = useRef(null);
  var publishedStreamId = useRef(null);

  useEffect(() => {
    //post warning
    let warning = {
      message: language.labels.live.warning,
      glyph: "!",
    };
    popMessage(warning);

    //fix bites
    let arr = [];
    for (var i = 0; i < state.selectedBites.length; i++) {
      const b = state.bites.filter((b) => b.bid === state.selectedBites[i]);
      arr.push(b[0]);
    }
    setBitesArr(arr);
  }, []);

  useEffect(() => {
    webRTCAdaptor.current = new WebRTCAdaptor({
      websocket_url: config.server.live.websocket_url,
      mediaConstraints: {
        video: true,
        audio: true,
      },
      peerconnection_config: {
        iceServers: [{ urls: config.server.live.ice }],
      },
      sdp_constraints: {
        OfferToReceiveAudio: false,
        OfferToReceiveVideo: false,
      },
      localVideoId: "localVideo",
      // bandwidth: 900, // default is 900 kbps, string can be 'unlimited'
      dataChannelEnabled: true, // enable or disable data channel
      callback: (info, obj) => {
        switch (info) {
          case "pong":
            break;
          case "closed":
            webRTCAdaptor.current.stop(publishedStreamId.current);
            webRTCAdaptor.current.closeStream(publishedStreamId.current);
            webRTCAdaptor.current = null;
            window.location.href = "/livestreams";
            break;
          case "initialized":
            console.log(info);
            setWebsocketConnected(true);
            break;
          case "publish_started":
            console.log(info);
            setPublishing(true);
            break;
          case "data_channel_opened":
            setDataChannel(true);
            break;
          case "data_received":
            try {
              let msg = JSON.parse(obj.data);
              let event = msg.type || "";
              switch (event) {
                case "started":
                  console.log("STARTED TS ", msg);
                  let x = trueTime(
                    msg.startTime,
                    language.locale,
                    language.labels.date
                  );
                  console.log("STARTED TIME ", x);
                  break;
                case "chat":
                  popMessage(msg.chat);
                  break;
                case "stream_resumed":
                  console.log("RESUMED");
                  break;
                case "stream_paused":
                  console.log("PAUSED");
                  break;
                case "count":
                  if (Number.isInteger(msg.count)) setActiveCount(msg.count);
                  break;
              }
            } catch (e) {}
            break;
          case "data_channel_closed":
            setDataChannel(false);
            break;
          case "publish_finished":
            closeStream();
            break;
          default:
            console.log(info);
            console.log(obj);
            break;
        }
      },
      callbackError: function (error, message) {
        closeStream();
      },
    });
  }, []);

  ////////////// PUBLISH //////////////////
  const initPublish = async () => {
    //init
    setError(null);
    let data = {
      type: "publish",
      streamType: "learning",
    };

    if (!streamName.trim() || !state.pathway || !state.selectedBites) return;

    data.streamName = streamName.trim();
    data.pathway = state.pid;
    data.bites = state.selectedBites;

    // retrieve token and stream id
    let result = await axiosCall("live/totp", data);
    console.log(result);
    if (result.success) {
      if (result.status === 200) {
        console.log(result.data);
        handlePublish(result.data);
      } else {
        setError(language.labels.live.already.publishing);
      }
    } else if (result.refresh) {
      //token has been refreshed, try again
      initPublish();
    } else {
      //refresh token expired or unknown error
      // signout();
    }
  };

  const handlePublish = (obj) => {
    if (obj.streamId && obj.subscriberId && obj.subscriberCode) {
      publishedStreamId.current = obj.streamId;
      webRTCAdaptor.current.publish(
        obj.streamId,
        "",
        obj.subscriberId,
        obj.subscriberCode,
        streamName.trim()
      );
      if (obj.started)
        setStreamStarted(
          trueTime(obj.started, language.locale, language.labels.date)
        );
    }
  };

  const closeStream = () => {
    webRTCAdaptor.current.closeWebSocket();
  };

  ////////////// PAUSE & MUTE //////////////////
  const toggleMute = () => {
    if (muted) {
      webRTCAdaptor.current.unmuteLocalMic();
      setMuted(false);
    } else {
      webRTCAdaptor.current.muteLocalMic();
      setMuted(true);
    }
  };

  const togglePause = async () => {
    let data = { streamId: publishedStreamId.current };
    if (!paused) data.status = true;

    console.log(data);

    let result = await axiosCall("live/pause", data);
    if (result.success) {
      if (paused) {
        webRTCAdaptor.current.turnOnLocalCamera();
        setMuted(false);
        setPaused(false);
        videoRef.current.style.minHeight = "0px";
      } else {
        videoRef.current.style.minHeight =
          videoRef.current.parentNode.offsetHeight + "px";
        webRTCAdaptor.current.turnOffLocalCamera();
        webRTCAdaptor.current.muteLocalMic();
        setMuted(true);
        setPaused(true);
      }
    } else if (result.refresh) {
      //token has been refreshed, try again
      togglePause();
    } else {
      console.log(result);
      //refresh token expired or unknown error
      // signout();
    }
  };

  ////////////// CHAT //////////////////
  const sendMessage = () => {
    let msg = msgRef.current.value.trim();
    if (msg) postMessage(msg);
  };

  const postMessage = (message) => {
    if (publishedStreamId.current) {
      const messageObj = {
        type: "chat",
        chat: {
          message,
          username: userData.username,
          avatar: userData.avatar,
          host: true,
        },
      };
      webRTCAdaptor.current.sendData(
        publishedStreamId.current,
        JSON.stringify(messageObj)
      );
      popMessage(messageObj.chat);
      msgRef.current.value = "";
    }
  };

  const popMessage = (message) => {
    //put message at start of array
    let _messages = [...messages];
    _messages.unshift(message);
    setMessages(_messages);
  };

  ////////////// RENDER GUI //////////////////
  const chat_map = messages.map((message, index) => {
    return (
      <div
        key={index}
        style={{
          display: "flex",
          marginTop: "0.5em",
          gap: "0.5em",
        }}
      >
        {message.avatar && (
          <div
            className="avatar medium"
            style={{
              flexShrink: 0,
              backgroundImage: `url("${
                config.content.server + message.avatar
              }")`,
            }}
          ></div>
        )}
        {message.glyph && (
          <div
            className="avatar medium glyphs alert"
            style={{
              fontSize: "1.2em",
              flexShrink: 0,
              textAlign: "center",
            }}
          >
            {message.glyph}
          </div>
        )}
        <div style={{ fontSize: "0.9em" }}>
          <div style={{ display: "flex", gap: "0.5em" }}>
            {message.username && (
              <div style={{ fontWeight: 500 }}>{message.username}</div>
            )}
            {message.host && <div>{language.labels.live.role_host}</div>}
            {message.expert && <div>{language.labels.live.role_expert}</div>}
          </div>
          <div
            className="quote-text"
            style={{ marginTop: `${message.glyph ? "0" : "0.2em"}` }}
          >
            {message.message}
          </div>
        </div>
      </div>
    );
  });

  return (
    <div
      className="page-section"
      style={{
        paddingBottom: "6em",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div className="live-container">
        {/* HEADINGS */}
        <h1 className="heading" style={{ textAlign: "center" }}>
          {language.labels.live.learning_stream}
        </h1>

        {/* live status */}
        <div
          className={streamStarted ? "alert" : publishing ? "amber" : "inert"}
          style={{
            fontWeight: "bold",
            margin: "1em",
            textAlign: "center",
          }}
        >
          {streamStarted
            ? language.labels.live.streaming.on
            : publishing
            ? language.labels.live.streaming.registering
            : language.labels.live.streaming.off}
        </div>

        {error && (
          <div
            className="errtext"
            role="alert"
            style={{ margin: "-0.5em 0 1em", textAlign: "center" }}
          >
            {error}
          </div>
        )}

        {publishing ? (
          // stream name, viewers and start time
          <div style={{ margin: "-0.5em 0 1em", textAlign: "center" }}>
            <div
              className="contrast"
              style={{ marginBottom: "0.5em", fontSize: "1.1em" }}
            >
              {streamName}
            </div>
            <div>
              {activeCount === 1
                ? language.labels.live.viewers.singular
                : language.labels.live.viewers.plural.replace(
                    /{n}/g,
                    activeCount
                  )}
            </div>
            {streamStarted && (
              <div style={{ marginTop: "0.5em" }}>
                {language.labels.live.started.replace(/{date}/g, streamStarted)}
              </div>
            )}
          </div>
        ) : (
          // input stream name, start, cancel
          <>
            <div style={{ textAlign: "center" }}>
              <input
                style={{
                  width: "100%",
                  maxWidth: "640px",
                  textAlign: "center",
                }}
                type="text"
                ref={nameRef}
                placeholder={language.labels.live.enter_name}
                value={streamName}
                onChange={(e) => setStreamName(e.target.value)}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "1em 0",
                gap: "1em",
              }}
            >
              <button
                className="button"
                disabled={!websocketConnected || !streamName.trim()}
                onClick={initPublish}
                style={{
                  opacity: `${
                    websocketConnected && streamName.trim() ? 1 : 0.5
                  }`,
                }}
              >
                {language.labels.live.start_session}
              </button>
              <button className="button-secondary" onClick={closeStream}>
                {language.labels.app.cancel}
              </button>
            </div>
          </>
        )}

        {/********* STAGE *********/}
        <div className="live-stage">
          {/* video */}
          <div className="live-video">
            {paused && (
              <div
                style={{
                  position: "absolute",
                  color: "white",
                  top: "40%",
                  width: "100%",
                  textAlign: "center",
                  padding: "1em",
                }}
              >
                {language.labels.live.paused_pub}
              </div>
            )}
            <video
              id="localVideo"
              ref={videoRef}
              controls={false}
              autoPlay={true}
              muted={true}
              style={{
                display: "block",
                transform: "scaleX(-1)",
                opacity: `${paused ? 0 : 1}`,
              }}
            ></video>

            {/* video control buttons */}
            <div className="live-control">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "1em",
                }}
              >
                {publishing && (
                  <>
                    {/* mic */}
                    <button
                      className={
                        muted
                          ? "glyphs live-button off"
                          : "glyphs live-button on"
                      }
                      onClick={toggleMute}
                      disabled={paused}
                    >
                      {muted ? '"' : "M"}
                    </button>

                    {/* pause/resume */}
                    <button
                      className={
                        paused
                          ? "glyphs live-button off"
                          : "glyphs live-button on"
                      }
                      onClick={togglePause}
                    >
                      {paused ? "W" : "U"}
                    </button>

                    {/* stop */}
                    <button
                      className="glyphs live-button stop"
                      onClick={closeStream}
                    >
                      ]
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>

          {/* chat box */}
          <div className="live-panel">
            <div
              className="live-panel-box"
              ref={msgBox}
              style={{
                position: "absolute",
                display: "flex",
                padding: "0.5em",
                flexDirection: "column-reverse",
                gap: "0.5em",
                overflowY: "auto",
                bottom: "50px",
                height: "Calc(100% - 50px)",
              }}
            >
              {chat_map}
            </div>
            {/* message input */}
            <div className="live-control message">
              {/* panel control buttons */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "0 0.5em",
                  gap: "0.25em",
                  width: "100%",
                }}
              >
                <input
                  type="text"
                  ref={msgRef}
                  style={{
                    opacity: `${dataChannel ? 1 : 0.6}`,
                    width: "Calc(100% - 32px)",
                  }}
                  disabled={!publishing}
                  placeholder={language.labels.live.enter_message}
                  onKeyUpCapture={(e) => e.key === "Enter" && sendMessage()}
                ></input>
                {/* send */}
                <button
                  aria-label={language.labels.aria.send_message}
                  disabled={!publishing}
                  className="glyphs live-button on"
                  style={{ opacity: `${dataChannel ? 1 : 0.3}` }}
                  onClick={() => sendMessage()}
                >
                  P
                </button>
              </div>
            </div>
          </div>
        </div>

        {/********* FOCUS  *********/}
        <div
          className="card-long"
          style={{
            display: "block",
            textAlign: "center",
            paddingBottom: "2em",
          }}
        >
          <h2
            className="heading2"
            style={{ fontWeight: 300, fontSize: "1.2em" }}
          >
            {language.labels.live.focus}
          </h2>
          {/* pathway */}
          <div
            style={{
              display: "flex",
              gap: "0.5em",
              justifySelf: "center",
              alignItems: "center",
              margin: "1.5em 0 1em",
            }}
          >
            <div role="img" className="thumb-glyph tiny auto-margin-narrow">
              p
            </div>
            <div style={{ fontWeight: 500 }}>{state.pathway}</div>
          </div>

          {/* bites */}
          {bitesArr.map((bite, index) => (
            <div
              style={{
                display: "flex",
                gap: "0.5em",
                justifySelf: "center",
                alignItems: "center",
                marginTop: "0.5em",
              }}
            >
              <div
                role="img"
                className="thumb-block border tiny auto-margin-narrow"
                style={{
                  backgroundImage: `url("${
                    config.content.server + bite.thumb
                  }")`,
                }}
              ></div>
              <div className="quote-text" style={{ fontSize: "0.9em" }}>
                {bite.title}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LivePublish;
